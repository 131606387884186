<template>
  <div>
    <el-form ref="baseformref" :model="risk" label-width="260px">
      <el-form-item :label="$t('fk.zdkhks')" prop="sitename">
        <el-select
          :placeholder="$t('fk.zdkhks')"
          style="width:100%"
          v-model="risk.isloss"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="search"
          @focus="searchuser = []"
        >
          <el-option
            v-for="item in searchuser"
            :key="item.id"
            :label="item.username"
            :value="item.username"
          >
          </el-option>
        </el-select>
        <el-alert :title="$t('fk.kstips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('fk.zdkhyl')" prop="service_url">
        <el-select
          :placeholder="$t('fk.zdkhyl')"
          style="width:100%"
          v-model="risk.iswin"
          multiple
          filterable
          remote
          reserve-keyword
          :remote-method="search"
          @focus="searchuser = []"
        >
          <el-option
            v-for="item in searchuser"
            :key="item.id"
            :label="item.username"
            :value="item.username"
          >
          </el-option>
        </el-select>
        <el-alert :title="$t('fk.yltips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <!--
      <el-form-item :label="$t('fk.zxfkz')" prop="sellurl">
        <el-input-number
          v-model="risk.riskmin"
          :precision="1"
          :step="0.1"
        ></el-input-number>
      </el-form-item>
       -->
      <el-form-item :label="$t('fk.fkgl')" prop="notice">
        <el-row v-for="(item, index) in risk.riskrule" :key="index">
          {{ $t('fk.zxz') }}：<el-input-number
            v-model="item.min"
            :min="0"
            :step="1"
          ></el-input-number>
          {{ $t('fk.zdz') }}：<el-input-number
            v-model="item.max"
            :step="1"
          ></el-input-number>
          {{ $t('fk.gailv') }}：<el-input-number
            v-model="item.rate"
            :min="0"
            :step="1"
          ></el-input-number>
          {{ $t('caozuo') }}：<el-button @click.prevent="delrow(index)">{{
            $t('shanchu')
          }}</el-button>
        </el-row>
        <el-button style="margin-top:15px" @click.prevent="addrow">{{
          $t('xinzeng')
        }}</el-button>
        <el-alert :title="$t('fk.fktips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      risk: {
        kui: [1, 2, 3, 4],
        ying: [2, 3, 4],
        riskmin: '',
        riskrule: []
      },
      searchuser: []
    }
  },
  created() {
    this.getrisk()
  },
  methods: {
    async getrisk() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/product/risk')
      if (data) {
        if (data.code === 200) {
          this.risk = data.data

            console.log(this.risk);





        } else {
          this.$message.error(data.msg)
        }
      }
      loading.close()
    },
    // 搜索用户
    async search(val) {
      if (!val) {
        return false
      }
      const { data } = await this.$http.post('/admin/product/searchuser', {
        username: val
      })
      if (data) {
        if (data.code === 200) {
          this.searchuser = data.data
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    },
    // 增加
    addrow() {
       console.log(this.risk.riskrule);


      this.risk.riskrule.push({
        min: '',
        max: '',
        rate: ''
      })
    },
    // 删除
    delrow(index) {
      if (index === 0) {
        return false
      }
      this.risk.riskrule.splice(index, 1)
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post('/admin/product/risk', this.risk)
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.getlang(data.msg))
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getbase()
    }
  }
}
</script>

<style lang="less" scoped>
.el-alert {
  margin-top: 15px;
}
</style>
